<template>
    <div>
        <Header :title="title"></Header>
        <div class="container px-0">
            <div class="empty_data h-500 text-pale d-flex justify-content-center align-items-center" v-if="push.length == 0">
                <div class="text-center">
                    <i class="fal fa-sticky-note fa-2x"></i>
                    <p>알림이 없습니다.</p>
                </div>
            </div>
            
            <ul v-if="push.length != 0">
                <li v-for="(item,index) in push" :key="index" class="push_item py-3 border-bottom small" :class="{'bg-light': item.chk}">
                    <a href="javascript:void(0)" class="d-flex align-items-center px-3">
                        <div class="txt_box">
                            <p class="tit">{{item.title}}</p>
                            <p class="desc small">{{item.desc}}</p>
                            <p class="time text-pale small">{{item.time}}</p>
                        </div>
                    </a>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import { eventBus } from '../../main'
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    created(){
    },
    data(){
        return{
            title: "알림",
            eventOn: false,
            
            push: [
                {
                    title: "차량번호 등록 완료",
                    desc: "서비스 이용을 위한 첫 번 째 준비를 마쳤습니다!",
                    time: "2021-02-03 ｜ 10:13:20",
                    chk: false,
                },
                {
                    title: "가입을 환영합니다",
                    desc: "마이파킹에서 쉽고 빠르게 차량을 주차해보세요!",
                    time: "2021-02-03 ｜ 10:13:20",
                    chk: true
                }
            ],

            infoPp: [{ on:true }],
        }
    },
    mounted(){
    },
    methods:{
        infoPpOn(idx){
            this.infoPp[idx].on = true;
        },
        infoPpOff(){
            for( let i=0; i<this.infoPp.length; i++ ){
                this.infoPp[i].on = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .txt_box{
        .tit{
            font-weight: bolder;
        }
    }

    .push_item:first-of-type{
        padding-top: 0 !important;
    }
</style>