<template>
    <div class="wallet">
        <Header :title="title"></Header>
        <div class="container px-0">
            <div class="withdrawal container">
                <div class="empty_data h-300 text-pale d-flex justify-content-center align-items-center" v-if="list.length == 0">
                    <div class="text-center">
                        <i class="fal fa-sticky-note fa-2x"></i>
                        <p>내역이 없습니다.</p>
                    </div>
                </div>
                <ul class="list last-border-0" v-if="filterList&&filterList.length>0">
                    <li class="d-flex justify-content-between" v-for="(item,index) in filterList" :key="index" @click="GoDetail(item.id)">
                        <div>
                            <p>{{item.title}}</p>
                            <span class="small text-gray-relative-500">{{item.address}}</span>
                        </div>
                        <div>
                            <i class="fas fa-star text-warning" @click.stop="item.bookmark=!item.bookmark"></i>
                        </div>
                    </li>
                </ul>
                <div class="text-center py-4" v-else>
                    <i class="fal fa-cars fa-4x d-block my-4 text-primary"></i>
                    즐겨찾는 주차장이 없습니다.

                </div>
            </div>
        </div>
        <InfoPopup ref="InfoPopup" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import InfoPopup from '@/components/reservation/InfoPopup.vue'

export default {
    components:{Header,InfoPopup},
    data(){
        return{
            title:'즐겨찾기',

            name: "홍길동",

            total_amount: 0,

            holding_amount: 0,
            traded_amount: 0,

            // floatPp:false,
            
            infoPp: [{on:false}, {on:false}],
            
            list: [
                {
                    name: "나이스 파크 하남 미사인스타시티 주차장",
                    location: "경기 하남시 풍산동 507-3",
                    favorite: true
                },
                {
                    name: "카카오 T 메인프라자 주차장",
                    location: "경기 하남시 풍산동 507-3",
                    favorite: true
                },
                {
                    name: "역삼빌딩 주차장",
                    location: "서울특별시 강남구 테헤란로14",
                    favorite: true
                },
                {
                    name: "NPD 점프밀라노 주차장",
                    location: "서울 강남구 강남대로 432",
                    favorite: true
                },
            ],
            
            items: [
                {
                    id: 0,
                    img: require('@/assets/img/sample_park.jpg'),
                    type: '민영',
                    title: '청담빌딩 주차장',
                    lot: 190,
                    lng: 127.0451749,
                    lat: 37.518136,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: true,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 도산대로 524',
                    contact: '012)345-6789',
                    bookmark: true,
                    affiliated: true,
                },
                {
                    id: 1,
                    img: require('@/assets/img/inhee_parking.png'),
                    type: '민영',
                    title: '인희빌딩 주차장',
                    lot: 190,
                    lng: 127.051588,
                    lat: 37.5243529,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울특별시 송파구 올림픽로 300',
                    contact: '012)345-6789',
                    bookmark: true,
                    affiliated: true,
                },
                {
                    id: 2,
                    img: require('@/assets/img/sample_park2.jpg'),
                    type: '민영',
                    title: '소니드빌딩 주차장',
                    lot:190,
                    lng : 127.0538529,
                    lat : 37.5259066,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울 강남구 도산대로 549',
                    contact: '02)345-6789',
                    bookmark: true,
                    affiliated: true,
                },
                {
                    id: 3,
                    img: require('@/assets/img/sample_park3.jpg'),
                    type: '민영',
                    title: '호텔 리베라 주차장',
                    lot:190,
                    lng : 127.0541241,
                    lat : 37.5238934,
                    sell: [
                        {
                            available: false,
                            title: '평일 1시간',
                            price: 4000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 4000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 영동대로 737',
                    contact: '02)345-6789',
                    bookmark: true,
                    affiliated: true,
                },
            ],
        }
    },
    computed:{
        filterList(){
            return this.items.filter((item)=>item.bookmark===true )
        }
    },
    methods:{
        GoDetail(id){
            this.$refs.InfoPopup.item_id = id; 
            this.$refs.InfoPopup.no_animation = true; 
            this.$refs.InfoPopup.FullPaged(); 
            this.$refs.InfoPopup.pp = true;
        }
    }
}
</script>

<style lang="scss" scoped>
    .wallet{
        padding-bottom: 140px;
    }
    .input_item{
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #eee;

        label{
            font-weight: bolder;
            min-width: 100px;
        }

        .item{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .btn{
                flex-shrink: 0;
                font-size: 13px;
            }
        }
    }

    // .float_btn{
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100vh;

    //     .dimm{
    //         background-color: rgba(0, 0, 0, 0.2);
    //         width: 100%;
    //         height: 100%;
    //         display: none;
    //     }

    //     ul{
    //         position: fixed;
    //         bottom: 130px;
    //         right: 10px;
    //         display: none;

    //         li{
    //             background-color: #fff;
    //             padding: 10px 20px;
    //             margin-bottom: 10px;
    //             border-radius: 10px;
    //             font-size: 14px;
    //             font-weight: bolder;
    //         }
    //     }

    //     .icon{
    //         background-color: #171c61;
    //         color: #fff;
    //         position: fixed;
    //         right: 10px;
    //         bottom: 70px;
    //         width: 50px;
    //         height: 50px;
    //         line-height: 50px;
    //         border-radius: 100%;
    //         text-align: center;
    //         font-size: 24px;
    //         transition: all 0.2s;
    //         z-index: 99;
    //     }

    //     &.on{
    //         z-index: 99;

    //         .dimm{
    //             display: block;
    //         }

    //         ul{
    //             display: block;
    //         }
    //         .icon{
    //             transform: rotate(-45deg);
    //         }
    //     }
    // }

    .withdrawal{
      .list{
        li{
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 10px 0;
          border-bottom: 1px solid #eee;
        
          .small{
              font-size: 11px;
          }
          strong{
            font-size: 16px;
            small{
              font-weight: normal;
            }
          }
        }
      }
    }
</style>