<template>
    <div class="wallet">
        <Header :title="title"></Header>
        <!-- <div class="container">
            <div class="p-3">
                <span class="small text-grey">예치금</span>
                <h4 class="fw-bolder mb-4 mt-2">
                    <span>{{total_amount}}원</span>
                </h4>
                <ul class="d-flex">
                    <li class="btn bg-light w-100 me-2" @click="infoPpOn(0)">입금하기</li>
                    <li class="btn bg-light w-100" @click="infoPpOn(1)">출금하기</li>
                </ul>
                <div class="bg-pale p-3 small text-gray-relative-500">
                    <div class="d-flex justify-content-between mb-2">
                        <span>보유중인 원화</span>
                        <span class="text-primary">{{holding_amount}}원</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span>거래중인 원화</span>
                        <span class="text-primary">{{traded_amount}}원</span>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="container px-0">
            <div class="border-bottom px-3 py-2">
                <p class="small py-1">전체</p>
            </div>
            <div class="withdrawal container">
                <div class="empty_data h-300 text-pale d-flex justify-content-center align-items-center" v-if="list.length == 0">
                    <div class="text-center">
                        <i class="fal fa-sticky-note fa-2x"></i>
                        <p>내역이 없습니다.</p>
                    </div>
                </div>
                <ul class="list last-border-0">
                    <li class="d-flex justify-content-between" v-for="(item,index) in list" :key="index" @click="$router.push(`/reservation-info/${item.id}/${item.idx}`)">
                        <!-- 기존에는 환불/영수증 확인 페이지 @click="$router.push('/reservation-detail/0')" -->
                        <div>
                            <p>{{item.title}}</p>
                            <!-- <span class="small text-gray-relative-500">{{item.date}}</span> -->
                        </div>
                        <div class="text-end">
                            <strong :class="{'text-danger' : item.type==0, 'text-blue' : item.type==1}" ><span v-if="item.type==1">-</span>{{numberWithCommas(item.price)}}<small> 원</small></strong>
                            <p class="small text-grey text-end" v-if="!item.done">({{item.sell_title}})</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- <div class="float_btn" :class="{'on': floatPp}">
            <div class="dimm" @click="floatPp = !floatPp"></div>
            <ul>
                <li @click="floatPp = !floatPp; infoPpOn(0)">입금하기</li>
                <li @click="floatPp = !floatPp; infoPpOn(1)">출금하기</li>
                <li>계좌등록</li>
            </ul>
            <i class="fal fa-plus icon" @click="floatPp = !floatPp"></i>
        </div> -->

        <!-- 입금하기 -->
        <div class="infoPp" v-if="this.infoPp[0].on">
            <div class="dimm" @click="infoPpOff()"></div>
            <div class="content">
                <div class="p-3">
                    <h4 class="fw-bold my-3">입금 정보</h4>
                    <div class="small">
                        <p class="mb-4">발급받은 계좌번호로 게좌이체 혹은 무통장 입금을 하면 원화(KRW)지갑에 충전됩니다.</p>
                        <p class="mb-4">가상 계좌는 1인당 1개의 계좌번호만 발급 가능합니다.</p>
                        <p class="mb-4 text-danger">최소 입금 가능 금액은 1만원 입니다.</p>
                    </div>
                    <h5 class="mb-2">가상계좌 정보</h5>
                    <div class="bg-light mb-4 p-3 rounded-md small">
                        <div class="d-flex justify-content-between mb-2">
                            <span>은행명</span>
                            <span>{{deposit.bank}}</span>
                        </div>
                        <div class="d-flex justify-content-between mb-2">
                            <span>계좌번호</span>
                            <b>{{deposit.account_num}} <i class="far fa-copy"></i></b>
                        </div>
                        <div class="d-flex justify-content-between">
                            <span>예금주</span>
                            <span>{{deposit.name}}</span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="btn bg-primary text-white w-100" @click="infoPpOff()">확인</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 출금하기 -->
        <div class="infoPp" v-if="infoPp[1].on">
            <div class="dimm" @click="infoPpOff()"></div>
            <div class="content">
                <div>
                    <h4 class="mb-3 fw-bolder">계좌 등록이 필요합니다</h4>
                    <p class="mb-4">출금을 하기 위해<br>계좌 등록이 필요합니다.<br><br>지금 진행하시겠습니까?</p>
                    <div class="d-flex">
                        <div class="btn bg-primary text-white me-2 w-100" @click="$router.push('/mypage/add-account')">확인</div>
                        <div class="btn bg-light w-100" @click="infoPpOff()">취소</div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title:'내 주차권',

            name: "홍길동",

            total_amount: 0,

            holding_amount: 0,
            traded_amount: 0,

            // floatPp:false,
            
            infoPp: [{on:false}, {on:false}],
            
            list: [
                {
                    id: 0,
                    idx: 1,
                    title: "청담빌딩 주차장",
                    date: "2023.09.20 09:00",
                    sell_title: "평일 2시간권",
                    price: 7000,
                    done: false
                },
                {
                    id: 1,
                    idx: 0,
                    title: "인희빌딩 주차장",
                    date: "2023.09.20 10:00",
                    sell_title: "평일 1시간권",
                    price: 5000,
                    done: false
                },
            ],

            
            items: [
                {
                    id: 0,
                    img: '',
                    type: '민영',
                    title: '청담빌딩 주차장',
                    lot: 190,
                    lng: 127.0451749,
                    lat: 37.518136,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: true,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 도산대로 524',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 1,
                    img: '',
                    type: '민영',
                    title: '인희빌딩 주차장',
                    lot: 190,
                    lng: 127.051588,
                    lat: 37.5243529,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울특별시 송파구 올림픽로 300',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 2,
                    img: '',
                    type: '민영',
                    title: '소니드빌딩 주차장',
                    lot:190,
                    lng : 127.0538529,
                    lat : 37.5259066,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울 강남구 도산대로 549',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 3,
                    img: '',
                    type: '민영',
                    title: '호텔 리베라 주차장',
                    lot:190,
                    lng : 127.0541241,
                    lat : 37.5238934,
                    sell: [
                        {
                            available: false,
                            title: '평일 1시간',
                            price: 4000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 4000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 영동대로 737',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
            ],


            deposit:{
                bank: '우리은행',
                name: '마이파킹-홍길동',
                account_num: '1231234512346'
            }
            
        }
    },
    methods:{
        infoPpOn(idx){
            this.infoPp[idx].on = true;
        },
        infoPpOff(){
            for( let i=0; i<this.infoPp.length; i++ ){
                this.infoPp[i].on = false;
            }
        },
        numberWithCommas(n){
            var parts=n.toString().split(".");
            return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
        }
    }
}
</script>

<style lang="scss" scoped>
    .wallet{
        padding-bottom: 140px;
    }
    .input_item{
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #eee;

        label{
            font-weight: bolder;
            min-width: 100px;
        }

        .item{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .btn{
                flex-shrink: 0;
                font-size: 13px;
            }
        }
    }

    // .float_btn{
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100vh;

    //     .dimm{
    //         background-color: rgba(0, 0, 0, 0.2);
    //         width: 100%;
    //         height: 100%;
    //         display: none;
    //     }

    //     ul{
    //         position: fixed;
    //         bottom: 130px;
    //         right: 10px;
    //         display: none;

    //         li{
    //             background-color: #fff;
    //             padding: 10px 20px;
    //             margin-bottom: 10px;
    //             border-radius: 10px;
    //             font-size: 14px;
    //             font-weight: bolder;
    //         }
    //     }

    //     .icon{
    //         background-color: #171c61;
    //         color: #fff;
    //         position: fixed;
    //         right: 10px;
    //         bottom: 70px;
    //         width: 50px;
    //         height: 50px;
    //         line-height: 50px;
    //         border-radius: 100%;
    //         text-align: center;
    //         font-size: 24px;
    //         transition: all 0.2s;
    //         z-index: 99;
    //     }

    //     &.on{
    //         z-index: 99;

    //         .dimm{
    //             display: block;
    //         }

    //         ul{
    //             display: block;
    //         }
    //         .icon{
    //             transform: rotate(-45deg);
    //         }
    //     }
    // }

    .withdrawal{
      .list{
        li{
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 10px 0;
          border-bottom: 1px solid #eee;
        
          .small{
              font-size: 11px;
          }
          strong{
            font-size: 16px;
            small{
              font-weight: normal;
            }
          }
        }
      }
    }
</style>