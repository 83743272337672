<template>
    <div class="mypage bg-gray-relative-100">
        <Header :title="title" :goPath="'/main/misa'"></Header>
            <div class="bg-body pt-5">
                <div class="container mt-3">
                    <div class="section_tit text-pale small">인증/보안</div>
                    <div class="menu_list_item border-0">
                        <div class="tit">지문인식 사용</div>
                        <div class="sub">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-body container mt-2 pt-3 pb-2">
                <div class="section_tit mb-1 text-pale small">알림 설정</div>
                <ul class="last-border-0">
                    <li class="menu_list_item" v-for="(item, index) in alarm_menu" :key="index">
                        <div class="tit">
                            <div>{{item.title}}</div>
                            <p class="small text-grey desc mt-2">{{item.desc}}</p>
                        </div>
                        <div class="sub">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" checked>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            
            <div class="bg-body container mt-2 pt-3 pb-2">
                <div class="section_tit mb-1 text-pale small">약관 및 정책</div>
                <ul class="last-border-0">
                    <li class="menu_list_item" v-for="(item, index) in terms_menu" :key="index">
                        <router-link :to="'/mypage/term/'+item.path">
                            <div class="tit">{{item.title}}</div>
                            <div class="sub">
                                <i class="fal fa-chevron-right"></i>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="bg-body container mt-2 pt-3 pb-2">
                <div class="section_tit mb-1 text-pale small">앱정보</div>
                <ul class="last-border-0">
                    <li class="menu_list_item">
                        <div class="tit">버전 정보</div>
                        <div class="sub">
                            1.5.9
                        </div>
                    </li>
                    <li class="menu_list_item">
                        <router-link :to="'/license'">
                            <div class="tit">오픈소스 라이선스</div>
                            <div class="sub">
                                <i class="fal fa-chevron-right"></i>
                            </div>
                        </router-link>
                    </li>
                    <li class="menu_list_item">
                        <a @click="logoutClick">
                            <div class="tit">로그아웃</div>
                            <div class="sub">
                                <i class="fal fa-chevron-right"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

    </div>
</template>

<script>
import { eventBus } from '../../main.js'
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title:'설정',
            alarm_menu: [
                {
                    title: "서비스 이용 알림",
                    desc: "서비스 이용(예치금 입출금, 청약, 거래 등)과 관련된 중요 알림을 보내드립니다.",
                },
                {
                    title: "마케팅 정보 알림",
                    desc: "마이파킹의 다양한 혜택과 소식을 알려드립니다."
                }
            ],
            terms_menu: [
                {
                    title: "동의한 약관 조회",
                    path: "terms",
                },
                {
                    title: "개인정보 처리방침",
                    path: "privacy"
                }
            ]
        }
    },
    methods: {
        logoutClick() {
            this.$router.push("/login")
        }
    },
}
</script>

<style lang="scss" scoped>

</style>