<template>
    <div class="mypage">
        <Header :title="title"></Header>
        <div class="container">
            <div class="small">
                <div class="input_item">
                    <label class="col-form-label">이름</label>
                    <div class="item">
                        <input type="text" readonly class="form-control-plaintext" :value="name">
                        <div class="btn btn-sm text-primary bg-pale">변경</div>
                    </div>
                </div>
                <div class="input_item">
                    <label class="col-form-label">이메일</label>
                    <div class="item">
                        <input type="text" readonly class="form-control-plaintext" :value="email">
                        <div class="btn btn-sm text-primary bg-pale">변경</div>
                    </div>
                </div>
                <div class="input_item">
                    <label class="col-form-label">휴대폰 번호</label>
                    <div class="item">
                        <input type="text" readonly class="form-control-plaintext" :value="phone">
                        <div class="btn btn-sm text-primary bg-pale">변경</div>
                    </div>
                </div>
                <div class="input_item">
                    <label class="col-form-label">차량 번호</label>
                    <div class="item">
                        <input type="text" readonly class="form-control-plaintext" :value="carNumber">
                        <router-link to="/mypage/add-car" class="btn btn-sm text-primary bg-pale">변경</router-link>
                    </div>
                </div>
            </div>
            <div class="mt-4 small text-gray-relative-500">
                <button class="btn btn-sm ps-0 small text-grey">회원 탈퇴</button>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title:'내 정보',

            name: "홍길동",
            phone: "010-1234-5678",
            email: "email@example.com",
            bank: "",
            account: "",

            
            local: '',
            carNumber: '108 가 1749',
        }
    },
    created(){
    }
}
</script>

<style lang="scss" scoped>

    .input_item{
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #eee;

        label{
            font-weight: bolder;
            min-width: 100px;
        }

        .item{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .btn{
                flex-shrink: 0;
                font-size: 13px;
            }
        }
    }
</style>