<template>
    <div class="add_account">
        <Header :title="title"></Header>
        <div class="container">
            <div>
                <div>
                    <ul>
                        <li class="border rounded mb-2" :class="{'border-primary':item.main}" v-for="(item,index) in list" :key="index">
                            <router-link to="/mypage/added-car/0" class="d-block p-3">
                                <div class="flex-between-center">
                                    <small class="text-gray-relative-500">차량번호</small>
                                    <div class="flex-start-center">
                                        <small class="bg-primary fs-px-11 me-2 pt-1 px-1 text-white rounded" v-show="item.main">대표차량</small>
                                        <i class="fal fa-thumbtack text-gray-400" :class="{'fas text-primary': item.main}"></i>
                                    </div>
                                </div>
                                <h4 class="fw-bold">{{item.number}}</h4>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="d-flex footer_btn">
            <div class="btn bg-primary text-white w-100 thick" @click="Add()">차량번호 추가하기</div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'

export default {
    components:{
        Header
    },
    data(){
        return{
            title: '차량번호 관리',
            list: [
                {
                    idx: 0,
                    main: true,
                    number: '108 가 1749',
                },
            ]
        }
    },
    computed:{
    },
    methods:{
        Add(){
            this.$router.push('/mypage/add-car')
        },
    }
}
</script>
<style scoped>
    .fa-thumbtack{
        transform: rotate(45deg)
    }
</style>