<template>
    <div>
        <Header :title="title"></Header>
        <div class="container">
            <pre class="small">{{item.desc}}</pre>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../main'
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title: "개인정보 처리방침",
            item:{
                title:'',
                desc:''
            },
            content: [
                {
                    title: "개인정보 처리방침",
                    desc: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste nesciunt rerum quos quaerat numquam ad. Nulla nobis iusto voluptatem expedita cumque quia quis nemo ab labore ut error eos illo, blanditiis voluptas, voluptatibus id dolorum molestias. Dolores nihil accusantium quae debitis velit ullam pariatur cumque voluptatum impedit incidunt consectetur, adipisci facere dolore! Illo doloribus nulla, hic similique alias aliquid repellendus dolores quis provident, consequatur at. Praesentium veritatis, molestiae impedit similique quibusdam ex doloribus accusantium consectetur quas in saepe quidem voluptatibus debitis pariatur harum culpa adipisci sapiente ducimus cum! Fuga a at, repudiandae quisquam cumque consequuntur iure dolor perspiciatis nam necessitatibus!
                    
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste nesciunt rerum quos quaerat numquam ad. Nulla nobis iusto voluptatem expedita cumque quia quis nemo ab labore ut error eos illo, blanditiis voluptas, voluptatibus id dolorum molestias. Dolores nihil accusantium quae debitis velit ullam pariatur cumque voluptatum impedit incidunt consectetur, adipisci facere dolore! Illo doloribus nulla, hic similique alias aliquid repellendus dolores quis provident, consequatur at. Praesentium veritatis, molestiae impedit similique quibusdam ex doloribus accusantium consectetur quas in saepe quidem voluptatibus debitis pariatur harum culpa adipisci sapiente ducimus cum! Fuga a at, repudiandae quisquam cumque consequuntur iure dolor perspiciatis nam necessitatibus!`
                },
                {
                    title: "동의한 약관 조회",
                    desc: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste nesciunt rerum quos quaerat numquam ad. Nulla nobis iusto voluptatem expedita cumque quia quis nemo ab labore ut error eos illo, blanditiis voluptas, voluptatibus id dolorum molestias. Dolores nihil accusantium quae debitis velit ullam pariatur cumque voluptatum impedit incidunt consectetur, adipisci facere dolore! Illo doloribus nulla, hic similique alias aliquid repellendus dolores quis provident, consequatur at. Praesentium veritatis, molestiae impedit similique quibusdam ex doloribus accusantium consectetur quas in saepe quidem voluptatibus debitis pariatur harum culpa adipisci sapiente ducimus cum! Fuga a at, repudiandae quisquam cumque consequuntur iure dolor perspiciatis nam necessitatibus!
                    
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste nesciunt rerum quos quaerat numquam ad. Nulla nobis iusto voluptatem expedita cumque quia quis nemo ab labore ut error eos illo, blanditiis voluptas, voluptatibus id dolorum molestias. Dolores nihil accusantium quae debitis velit ullam pariatur cumque voluptatum impedit incidunt consectetur, adipisci facere dolore! Illo doloribus nulla, hic similique alias aliquid repellendus dolores quis provident, consequatur at. Praesentium veritatis, molestiae impedit similique quibusdam ex doloribus accusantium consectetur quas in saepe quidem voluptatibus debitis pariatur harum culpa adipisci sapiente ducimus cum! Fuga a at, repudiandae quisquam cumque consequuntur iure dolor perspiciatis nam necessitatibus!`
                }
            ]
        }
    },
    created(){
        if(this.$route.params.idx=='terms'){
            this.title=this.content[1].title
            this.item.title=this.content[1].title
            this.item.desc=this.content[1].desc
        }else{
            this.title=this.content[0].title
            this.item.title=this.content[0].title
            this.item.desc=this.content[0].desc
        }
        // eventBus.pageTitle(this.title);
    }
}
</script>

<style lang="scss" scoped>
hr{
    border-color:#eee;
}
</style>