<template>
    <div class="add_account">
        <Header :title="title"></Header>
        <div class="container">
            <div>
                <div>
                    <div class="mb-2">
                        <div class="flex-between-center">
                            <small class="text-gray-relative-500">차량번호</small>
                        </div>
                        <h3 class="fw-bold">{{item.number}}</h3>
                    </div>
                    <div class="bg-gray-relative-100 rounded flex-between-center p-2">
                        <small>대표 차량으로 등록하기</small>
                        <div class="round_checkbox">
                            <input type="checkbox" v-model="item.main" id="check1">
                            <label for="check1">
                                <div class="chk_icon w-px-16 h-px-16"></div>
                            </label>
                        </div>
                    </div>
                    <div class="flex-start-center mt-4">
                        <small class="text-gray-relative-400">삭제</small>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'

export default {
    components:{
        Header
    },
    data(){
        return{
            title: '차량 정보 수정',
            item:{
                    idx: 0,
                    main: true,
                    number: '108 가 1749',
                },
        }
    },
    computed:{
    },
    methods:{
        Add(){

        },
    }
}
</script>
<style scoped>
    .fa-thumbtack{
        transform: rotate(45deg)
    }
</style>