<template>
    <div class="mypage bg-gray-relative-100">
        <Header :title="title" :goPath="'/'"></Header>
            <div class="bg-body pt-5 pb-3">
                <div class="container mt-3 px-4">
                    <!-- <div class="section_tit text-pale small">내 정보</div> -->
                    <div class="account">
                        <router-link to="/mypage/myinfo" class="flex-between-center py-3">
                            <img src="https://cdn-icons-png.flaticon.com/512/1326/1326405.png" class="h-px-100 w-px-100 avatar rounded-circle shadow" alt="" height="50">
                            <!-- <div class="tit h5 fw-bold">홍길동<span class="fw-normal"> 님</span>
                            </div>
                            <div>
                                <i class="fal fa-chevron-right"></i>
                            </div> -->
                        </router-link>
                    </div>
                    <!-- <div>
                        <router-link to="/mypage/assets" class="btn px-3 w-100 bg-pale text-primary menu_list_item mt-2 border-0">
                            <div>
                                <i class="fal fa-sticky-note me-2"></i>
                                내 주차권
                            </div>
                            <div>
                                0 매 <i class="fal fa-chevron-right text-primary ms-2"></i>
                            </div>
                        </router-link>
                    </div> -->
                    <div class="py-3">
                        <div class="small text-muted">이메일 주소</div>
                        <b class="">email@example.com</b>
                    </div>
                    <div class="py-3">
                        <div class="small text-muted">휴대폰 번호</div>
                        <b class="">010-1234-5678</b>
                    </div>
                    <div class="py-3">
                        <div class="small text-muted">차량 번호</div>
                        <b class="">{{local}} {{carNumber}}</b>
                    </div>
                    <!-- <div class="menu_list_item align-items-center">
                        <div class="tit">연간 잔여 투자한도</div>
                        <div class="sub text-end d-flex align-items-center">
                            <div class="me-3">
                                <span class="small text-gray-relative-500">일반 투자자</span>
                                <p class="fw-bolder">20,000,000원</p>
                            </div>
                            <div>
                                <i class="fal fa-chevron-right"></i>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="bg-body container mt-2 pt-3 pb-2">
                <div class="section_tit mb-1 text-pale small">고객센터</div>
                <ul  class="last-border-0">
                    <li class="menu_list_item" v-for="(item, index) in customer_menu" :key="index">
                        <router-link :to="'/cs/'+item.path">
                            <div class="tit">{{item.title}}</div>
                            <div class="sub">
                                <i class="fal fa-chevron-right"></i>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
            
            <div class="bg-body container mt-2 pt-3 pb-2">
                <div class="section_tit mb-1 text-pale small">기타</div>
                <ul class="last-border-0">
                    <li class="menu_list_item">
                        <router-link to="/mypage/setting">
                            <div class="tit">환경설정</div>
                            <div class="sub">
                                <i class="fal fa-chevron-right"></i>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>
import { eventBus } from '../../main.js'
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title:'마이페이지',
            local: '',
            carNumber: '108 가 1749',
            customer_menu: [
                {
                    title: "FAQ",
                    path: "faq",
                },
                {
                    title: "내 문의 내역",
                    path: "inquiry",
                },
                {
                    title: "공지사항",
                    path: "notice",
                },
                // {
                //     title: "증명서 발급",
                //     path: "certificate",
                // },
                // {
                //     title: "증명서 발급 내역",
                //     path: "certificate_list",
                // },
            ],
            alarm_menu: [
                {
                    title: "서비스 이용 알림",
                    desc: "서비스 이용(예치금 입출금, 청약, 거래 등)과 관련된 중요 알림을 보내드립니다.",
                },
                {
                    title: "마케팅 정보 알림",
                    desc: "마이파킹의 다양한 혜택과 소식을 알려드립니다."
                }
            ],
            terms_menu: [
                {
                    title: "동의한 약관 조회",
                    path: "terms",
                },
                {
                    title: "개인정보 처리방침",
                    path: "privacy"
                }
            ]
        }
    },
    methods: {
        logoutClick() {
            this.$store.dispatch('SETLOGOUT').then(() => { this.$router.push("/main") });
        }
    },
}
</script>

<style lang="scss" scoped>
    .mypage{
        // min-height: 100vh;
    }
</style>