<template>
    <div class="add_account">
        <Header :title="title"></Header>
        <div class="container">
            <div class="px-3 pb-4 border-bottom">
                <p class="mt-2 text-grey small">
                    <span>마이파킹 서비스 이용을 위해 차량 번호를 등록하거나 변경합니다.</span>
                </p>
            </div>
            <div class="p-3">
                <div>
                    <ul class="d-flex gap-2 text-center mb-3">
                        <li class="w-50 btn py-3" :class="{'btn-primary':type===0,'btn-outline-primary':type!==0}" @click="type=0">일반 번호판</li>
                        <li class="w-50 btn py-3" :class="{'btn-primary':type===1,'btn-outline-primary':type!==1}" @click="type=1">지역 번호판</li>
                    </ul>
                    <select class="form-select py-2 mb-2 text-secondary" aria-label="은행" v-model="local" v-if="type===1">
                        <option value="">지역명</option>
                        <option value="">강원</option>
                        <option value="">경기</option>
                        <option value="">경남</option>
                        <option value="">경북</option>
                        <option value="">광주</option>
                        <option value="">대구</option>
                        <option value="">대전</option>
                        <option value="">부산</option>
                        <option value="">서울</option>
                        <option value="">세종</option>
                        <option value="">울산</option>
                        <option value="">인천</option>
                        <option value="">전남</option>
                        <option value="">전북</option>
                        <option value="">제주</option>
                        <option value="">충남</option>
                        <option value="">충북</option>
                    </select>
                    
                    <div class="input-group mb-2">
                        <input type="text" class="form-control py-2" placeholder="차량번호" v-model="carNumber">
                    </div>
                </div>

                <div class="mt-4">
                    <span class="fw-bolder">차량번호 등록 안내 및 유의사항</span>
                    <ul class="fw-lighter small my-3">
                        <li class="mb-2">- 차량번호를 정확히 입력해주세요.</li>
                        <li class="mb-2">- 임시번호판 차량은 이용이 불가합니다.</li>
                        <li>- 지역번호판 입력 시 차량번호판 속 지역명과 동일해야 합니다.</li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="d-flex footer_btn">
            <div class="btn bg-primary text-white w-100 thick" @click="Add()">등록</div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'

export default {
    components:{
        Header
    },
    data(){
        return{
            title: '차량번호등록',
            local: '',
            type:0,
            carNumber:''
        }
    },
    created(){
    },
    methods:{
        Add(){
            this.$router.push('/mypage/added-car');
        }
    }
}
</script>
